import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import MuiPaper from "@material-ui/core/Paper";

import Layout from "../components/layout";

const Paper = styled(MuiPaper)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: auto calc(20vw - 4rem);
`;

const FillHeight = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
`;

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <FillHeight>
      <Paper>
        <Typography variant="h1">NOT FOUND</Typography>
        <Typography variant="body1">
          This is not the page you're looking for
        </Typography>
      </Paper>
    </FillHeight>
  </Layout>
);

export default NotFoundPage;
